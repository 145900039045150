import { MapView } from "./components/map/MapView";
import { BookingDetail } from "./components/booking/BookingDetail";
import { HomeInformation } from "./components/home/HomeInformation";
import { LocationInfoForm } from "./components/location/LocationInfoForm";
import { Listing } from "./components/listing/Listing";
import { OperationsInfo } from "./components/operations/OperationsInfo";
import { useAccessAssetDetail } from "@/providers/roles/AccessHooks";
import { Form, SubmitButtons } from "@/Components/Form";

export const AssetDetailTab = ({ asset, account, ...props }) => {
	const { canWrite } = useAccessAssetDetail();

	return (
		<div style={{ padding: ".5rem" }}>
			<Form assetId={asset.id} options={props}>
				<BookingDetail assetId={asset.id} isReadOnly={!canWrite} />
				<HomeInformation assetId={asset.id} isReadOnly={!canWrite} />
				<LocationInfoForm assetId={asset.id} isReadOnly={!canWrite} />
				<Listing assetId={asset.id} isReadOnly={!canWrite} />
				<OperationsInfo assetId={asset.id} isReadOnly />
				<SubmitButtons />
			</Form>
			<MapView id={asset.id} />
		</div>
	);
};
