import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { DataTableCell } from "@salesforce/design-system-react";
import { get } from "lodash";
import { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useFormSection } from "@/Components/Forms";
import { StaticInput } from "./StaticInput";
import { getColumn } from "./utils";
import { useIsEditable } from "@/Components/Forms";
import { WorksheetCellProps } from "../types";

export const AccountBalanceCell = ({
	children,
	...props
}: WorksheetCellProps) => {
	const {
		field: { value },
	} = useController({ name: children as string });

	const {
		formState: { isDirty },
		setValue,
	} = useFormContext();

	const isEditable = useIsEditable();

	const { assetId } = useAssetId();
	const { data: asset } = useGetAssetQuery(assetId);
	const accountBalance = asset?.account?.balance;

	const { isStatic } = useFormSection(getColumn(children as string));

	useEffect(() => {
		if (
			isDirty &&
			isEditable &&
			accountBalance !== undefined &&
			accountBalance !== value
		) {
			setValue(children as string, accountBalance, { shouldDirty: false });
		}
	}, [accountBalance, children, isDirty, isEditable, setValue, value]);

	return (
		<DataTableCell title={`${props.label} ${props?.item?.category}`} {...props}>
			<StaticInput
				containerStyle={{ paddingRight: isStatic ? "1.5rem" : "1rem" }}
			>
				{value ?? get(props.data, children as string)}
			</StaticInput>
		</DataTableCell>
	);
};

AccountBalanceCell.displayName = DataTableCell.displayName;
