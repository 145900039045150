import { Button } from "@salesforce/design-system-react";
import { BookingConfig } from "./BookingConfig";
import classes from "@/Components/ControlledForm.module.scss";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BOOKING_SECTION_TITLE, SPECIAL_INSTRUCTIONS } from "./constants";
import textAreaClasses from "@/Components/Form/ControlledTextarea/ControlledTextArea.module.scss";
import { ControlledTextarea as Textarea } from "@/Components/Form";
import {
	FormSection,
	useFormSection,
} from "@/Components/Form/ControlledForm/FormSection";

export const BookingDetail = ({ assetId, ...props }) => {
	return (
		<FormSection title={BOOKING_SECTION_TITLE} {...props}>
			<BookingDetailFields homeId={assetId} />
		</FormSection>
	);
};

function BookingDetailFields({ homeId }) {
	const { isLoaded, control, watch, isConcurrencyError } = useFormContext();

	const { isStatic } = useFormSection();

	const { fields, append, remove } = useFieldArray({
		name: SPECIAL_INSTRUCTIONS,
		control,
	});

	const watchFieldArray = watch(SPECIAL_INSTRUCTIONS);

	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchFieldArray[index],
		};
	});

	const fullWidthTextArea =
		!isConcurrencyError && !isStatic
			? {
					display: "grid",
					gridTemplateColumns: "1fr 2rem",
			  }
			: {};
	return (
		isLoaded && (
			<>
				<div className={classes.formGrid}>
					<Fields config={BookingConfig} />
					{controlledFields.map((field, index) => {
						return (
							<div
								key={`${SPECIAL_INSTRUCTIONS}-${index}`}
								className={textAreaClasses.gridItem}
								style={fullWidthTextArea}
							>
								<Textarea
									key={field.id}
									className={isStatic ? undefined : ""}
									name={`${SPECIAL_INSTRUCTIONS}[${index}].note`}
									label={`Special Instruction ${index > 0 ? index + 1 : ""}`}
								/>
								{!isStatic && !isConcurrencyError && (
									<div style={{ display: "grid", placeItems: "center" }}>
										<Button
											assistiveText={{
												icon: `remove special instruction ${index + 1}`,
											}}
											variant="icon"
											iconVariant="bare"
											iconName="delete"
											iconCategory="utility"
											onClick={() => {
												remove(index);
											}}
										/>
									</div>
								)}
							</div>
						);
					})}
					{!isStatic && !isConcurrencyError && (
						<div className={textAreaClasses.gridItem}>
							<Button
								onClick={() => append({ note: "", homeId })}
								variant="link"
							>
								Add Special Instruction
							</Button>
						</div>
					)}
				</div>
			</>
		)
	);
}

function Fields({ config }) {
	return (
		<>
			{config.map(({ component: Component, props }) => {
				return <Component key={props.name} {...props} />;
			})}
		</>
	);
}
