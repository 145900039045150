import { StaticInput } from "./StaticInput";
import { DataTableCell } from "@salesforce/design-system-react";
import { get } from "lodash";
import { useEffect, useMemo } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import {
	LOT_RENT,
	TRANSPORT_TO_HOME_CENTER,
	DELIVERY,
	APPLIANCES,
	HVAC,
	MOWING,
	UTILITIES,
	TRASH_OUT,
	DECK,
	F_H_A_PREP,
	GENERAL_REFURB,
	OTHER_REFURBISHING_EXEPENSES,
	LAND_PURCHASE_PRICE,
	CLOSING_COSTS,
	BUILDING_PERMIT,
	LAND_CLEARING,
	DRIVEWAY,
	GRAVEL,
	FOOTERS,
	FRENCH_DRAIN,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	WATER_LINE,
	WELL_PUMP,
	ELECTRIC,
	OTHER_LAND_IMPROVEMENTS,
	OTHER_EXPENSES,
	SURVEY,
	COMMISSION,
	CREDITS,
} from "../constants";
import { getFieldNameForColumn, getColumn } from "./utils";
import { useFormSection } from "@/Components/Forms";
import { WorksheetCellProps } from "../types";

export const TotalExpensesCell = ({
	children,
	...props
}: WorksheetCellProps) => {
	const {
		field: { onChange },
	} = useController({ name: children as string });

	const getFieldName = getFieldNameForColumn(getColumn(children as string));

	const getFieldNames = (constant: { key: string }) =>
		getFieldName(constant.key);

	const fields = useWatch({
		name: [
			LOT_RENT,
			TRANSPORT_TO_HOME_CENTER,
			DELIVERY,
			APPLIANCES,
			HVAC,
			MOWING,
			UTILITIES,
			TRASH_OUT,
			DECK,
			F_H_A_PREP,
			GENERAL_REFURB,
			OTHER_REFURBISHING_EXEPENSES,
			LAND_PURCHASE_PRICE,
			CLOSING_COSTS,
			BUILDING_PERMIT,
			LAND_CLEARING,
			DRIVEWAY,
			GRAVEL,
			FOOTERS,
			FRENCH_DRAIN,
			SEPTIC,
			SEPTIC_PERMIT,
			SEWER_LINE,
			WATER_LINE,
			WELL_PUMP,
			ELECTRIC,
			OTHER_LAND_IMPROVEMENTS,
			OTHER_EXPENSES,
			SURVEY,
			COMMISSION,
			CREDITS,
		].map(getFieldNames),
	});

	const { isStatic } = useFormSection(getColumn(children as string));

	const initialValue = Number(get(props.data, children as string));

	const { isEditable } = useFormSection();
	const {
		formState: { isDirty },
	} = useFormContext();

	const fieldsExist = useMemo(
		() => fields.reduce((acc, c) => acc + Number(c) > 0, 0) > 0,
		[fields]
	);

	const totalExpenses = useMemo(() => {
		const credits = fields.slice(fields.length - 1)[0];

		const sum = fields.slice(0, fields.length - 1).reduce((acc, c) => {
			return Number(acc) + Number(c);
		}, 0);

		return Number((sum - credits).toFixed(2));
	}, [fields]);

	useEffect(() => {
		if (
			isDirty &&
			isEditable &&
			fieldsExist &&
			totalExpenses !== initialValue
		) {
			onChange(totalExpenses);
		}
	}, [fieldsExist, initialValue, isDirty, isEditable, onChange, totalExpenses]);

	return (
		<DataTableCell {...props}>
			<StaticInput
				containerStyle={{
					paddingRight: isStatic ? "1.5rem" : "1rem",
				}}
			>
				{totalExpenses.toFixed(2) ?? get(props.data, children as string)}
			</StaticInput>
		</DataTableCell>
	);
};

TotalExpensesCell.displayName = DataTableCell.displayName;
