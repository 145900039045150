import React, { useCallback } from "react";
import { Button, Icon, Tooltip } from "@salesforce/design-system-react";
import { useState } from "react";
import styles from "../PricingWorksheet.module.scss";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import dayjs from "dayjs";
import { PricingInfoHistory } from "./PricingInfoForm";
import { gatewayApi } from "@/ReduxToolkit/GatewayApi";
import { upperFirst } from "lodash";

type Change = {
	modifiedDate: string;
	submittedBy: string;
	value: string | number;
};

export function HoverHistoryTooltip({
	assetId,
	name,
	label,
}: {
	assetId: string;
	name: string;
	label: string;
}) {
	const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
	const { data } = gatewayApi.endpoints.getAsset.useQueryState(assetId, {
		selectFromResult: ({ data }) => {
			return {
				data: data?.marketingInfo?.pricingInfo?.pricingInfoHistory ?? [],
			};
		},
	});

	const { data: users } = useGetAllUsersQuery(undefined);

	const getChanges = useCallback(
		(field: string) =>
			data
				?.filter((change) => change.propertyPath === upperFirst(field))
				.sort(() => -1) || [],
		[data]
	);

	const history: Change[] = getChanges(name)?.map(
		(change: PricingInfoHistory) => {
			const user = users?.find(
				(user) => user?.email?.toLowerCase() === change?.user?.toLowerCase()
			);
			const valueFormat = new Intl.NumberFormat("en-US");

			return {
				modifiedDate: dayjs(change.modifiedDate).format("MM/DD/YYYY"),
				submittedBy: `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
				value: valueFormat.format(change.value),
			};
		}
	);

	const [page, setPage] = useState(0);
	const pageLength = 5;
	const numPages = Math.ceil(history.length / pageLength);

	const paginatedHistory = isOpen
		? history.slice(page * pageLength, pageLength * (page + 1))
		: history.slice(0, 5);

	const prevPage = () => {
		setPage(page - 1);
	};

	const nextPage = () => {
		setPage(page + 1);
	};

	return (
		<Tooltip
			id={`history-tooltip-${name}`}
			dialogClassName={styles.toolTipHover}
			content={
				<div
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.stopPropagation();
					}}
				>
					<div className={styles.closeButton}>
						{isOpen && (
							<Button
								variant="base"
								onClick={(e: MouseEvent) => {
									e.stopPropagation();
									setIsOpen(undefined);
								}}
							>
								<Icon name="close" category="utility" size="x-small" inverse />
							</Button>
						)}
					</div>
					<table>
						<thead>
							<tr>
								<td
									colSpan={3}
									className={styles.title}
								>{`${label} History`}</td>
							</tr>
							<tr>
								<th>Date</th>
								<th>By</th>
								<th className={styles.alignRight}>Amount</th>
							</tr>
						</thead>
						<tbody>
							{paginatedHistory.map((change: Change, index) => (
								<tr key={`history-table-row-${index}`}>
									<td>
										{dayjs(change.modifiedDate as string).isValid() &&
											dayjs(change.modifiedDate as string).format("MM/DD/YYYY")}
									</td>
									<td>{change.submittedBy}</td>
									<td className={styles.alignRight}>${change.value}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className={styles.historyWrapper}>
						{isOpen ? (
							<>
								<Button
									variant="base"
									disabled={page === 0}
									onClick={(e: MouseEvent) => {
										e.stopPropagation();
										prevPage();
									}}
								>
									<Icon
										inverse
										name="chevronup"
										category="utility"
										size="x-small"
									/>
								</Button>
								<Button
									variant="base"
									disabled={page === numPages - 1}
									onClick={(e: MouseEvent) => {
										e.stopPropagation();
										nextPage();
									}}
								>
									<Icon
										inverse
										name="chevrondown"
										category="utility"
										size="x-small"
									/>
								</Button>
							</>
						) : (
							<>
								Click
								<Icon
									inverse
									name="info"
									category="utility"
									size="x-small"
									containerStyle={{ margin: "0 .25rem" }}
								/>
								to show more.
							</>
						)}
					</div>
				</div>
			}
			onClickTrigger={(e: MouseEvent) => {
				e.stopPropagation();
				setPage(0);
				setIsOpen(isOpen ? undefined : true);
			}}
			isOpen={isOpen}
		/>
	);
}
