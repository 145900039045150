import { DataTableCell } from "@salesforce/design-system-react";
import { get } from "lodash";
import { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { BASE_PRICE, TOTAL_EXPENSES } from "../constants";
import { useFormSection, useIsEditable } from "@/Components/Forms";
import { StaticInput } from "./StaticInput";
import { getFieldNameForColumn, getColumn } from "./utils";
import { WorksheetCellProps } from "../types";
// import { useIsEditable } from "../hooks/useIsEditable";

export const NetSaleCell = ({ children, ...props }: WorksheetCellProps) => {
	const {
		field: { value, onChange },
	} = useController({ name: children as string });

	const isEditable = useIsEditable();

	const {
		formState: { isDirty },
	} = useFormContext();

	const { isStatic } = useFormSection(getColumn(children as string));

	const getFieldName = getFieldNameForColumn(getColumn(children as string));

	const [basePrice, totalExpenses] = useWatch({
		name: [getFieldName(BASE_PRICE.key), getFieldName(TOTAL_EXPENSES.key)],
	});

	const initialValue = Number(get(props.data, children as string));

	const calculation = Number(
		(Number(basePrice) - Number(totalExpenses)).toFixed(2)
	);

	useEffect(() => {
		if (
			isDirty &&
			isEditable &&
			(Number(basePrice) || Number(totalExpenses)) &&
			calculation !== initialValue
		) {
			onChange(calculation);
		}
	}, [
		calculation,
		basePrice,
		totalExpenses,
		onChange,
		initialValue,
		isDirty,
		isEditable,
	]);

	return (
		<DataTableCell title={`${props.label} ${props.item?.category}`} {...props}>
			<StaticInput
				containerStyle={{
					paddingRight: isStatic ? "1.5rem" : "1rem",
				}}
			>
				{value?.toFixed(2) ?? get(props.data, children as string)}
			</StaticInput>
		</DataTableCell>
	);
};

NetSaleCell.displayName = DataTableCell.displayName;
