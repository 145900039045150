import { useEffect } from "react";
import {
	FieldValues,
	FormProvider,
	FormState,
	Resolver,
	UseFormProps,
	UseFormReturn,
	useForm,
} from "react-hook-form";
import { ReactNode } from "react";
import {
	FormFocusProvider,
	FormResetProvider,
	FormSectionProvider,
} from "../providers";
import { SubmitButtons } from "@/Components/Forms";
import { ActivateSectionCallbackArgs } from "../providers/FormSectionProvider";

interface FormProps<T extends FieldValues> {
	children: ReactNode;
	defaultValues: () => Promise<T>;
	resolver?: Resolver<T>;
	onSubmit: (data: T) => Promise<void>;
	onOpenSection?: (arg: ActivateSectionCallbackArgs) => void;
	isFetching: boolean;
	isLoading: boolean;
	isPatching?: boolean;
	isEditable?: boolean | ((arg: { formState: FormState<T> }) => boolean);
	formId: string;
	data: T;
	context?: (methods: UseFormReturn<T, any>) => object | object;
	reValidateMode?: UseFormProps["reValidateMode"];
}

export const Form = <T extends FieldValues>({
	children,
	defaultValues,
	resolver,
	onSubmit,
	onOpenSection,
	isFetching,
	isEditable,
	isLoading,
	isPatching,
	formId,
	data,
	context,
	...useFormProps
}: FormProps<T>) => {
	const methods = useForm<T>({
		defaultValues,
		resolver,
		context: contextCallback,
		...useFormProps,
	});

	function contextCallback() {
		return typeof context === "function" ? context(methods) : context ?? {};
	}

	let isEditableArg;

	if (isEditable && typeof isEditable === "boolean") {
		isEditableArg = isEditable;
	} else if (isEditable && typeof isEditable === "function") {
		isEditableArg = isEditable({ formState: methods.formState });
	}

	useEffect(() => {
		methods.reset(data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, isLoading, isPatching]);

	return (
		<FormProvider {...methods}>
			<FormResetProvider>
				<FormFocusProvider>
					<FormSectionProvider
						formId={formId}
						isEditable={
							isEditableArg ?? (!methods.formState.isSubmitting && !isFetching)
						}
						isFetching={isFetching}
						onActivateSection={onOpenSection}
					>
						<form onSubmit={methods.handleSubmit(onSubmit)} id={formId}>
							{children}
							<SubmitButtons form={formId} />
						</form>
					</FormSectionProvider>
				</FormFocusProvider>
			</FormResetProvider>
		</FormProvider>
	);
};
