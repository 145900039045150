import { DataTableCell } from "@salesforce/design-system-react";
import { get } from "lodash";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useFormSection } from "@/Components/Forms";
import { subCategories } from "./PricingWorksheetForm";
import { StaticInput } from "./StaticInput";
import { getColumn, getFieldNameForColumn } from "./utils";
import { WorksheetCellProps } from "../types";

export const SubtotalCell = ({ children, ...props }: WorksheetCellProps) => {
	const getFieldName = getFieldNameForColumn(getColumn(children as string));

	const fields = useWatch({
		name: subCategories[props.item?.id as string].map(getFieldName),
	});

	const { isStatic } = useFormSection(getColumn(children as string));

	const subtotal = useMemo(() => {
		const sum = fields.reduce(
			(acc: number, c: number) => Number(acc) + Number(c),
			0
		);
		return Number(sum.toFixed(2));
	}, [fields]);

	return (
		<DataTableCell {...props}>
			<StaticInput
				containerStyle={{
					paddingRight: isStatic ? "1.5rem" : "1rem",
				}}
			>
				{subtotal.toFixed(2) ?? get(props.data, children as string)}
			</StaticInput>
		</DataTableCell>
	);
};

SubtotalCell.displayName = DataTableCell.displayName;
